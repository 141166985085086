import React, { useContext, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import notificationTypes from '../../../constants/modalNotificationConst';
import { ASAP } from '../../../constants/deliveryType';

import { getProductAttributeValue } from '../../../helpers/productAttributeHelper';
import getHighestValueAttribute from '../../../helpers/getHighestValueAttribute';
import getTransformedImageURLs from '../../../helpers/getTransformedImageURLs';
import getDefaultTab from '../../../helpers/getDefaultTab';

import useSegment from '../../../hooks/segment';

import appContext from '../../../Context/appContext';

import AddToCartButton from '../../AddToCartButton';
import ProductCounter from '../../ProductCounter';
import LoadImage from '../../LoadImage';
import Price from '../../Price';
import { isWishlistApplicable } from '../../../constants/feature';
import FavouritesIcon from './FavouritesIcon';
import storageService from '../../../services/storageService';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification, popNotification, increaseURLstack } from '../../../redux/slices/modalSlice';
import { setUrlBeforeProduct } from '../../../redux/slices/userSlice';
import { useCallback } from 'react';
import { setPositionID } from '../../../redux/slices/otherSlice';

const FeaturedProductTile = props => {
  const {
    productDetails,
    productIndex,
    initialLocationAddress,
    initialFreightLimit,
    initialPurchaseLimit,
    initialUpsellProducts,
    initialDeliveryAddressDetails,
    from,
    fromPageType,
    lastSlotCutOff,
    asapEndTime,
    asapStartTime,
    asapEnabled
  } = props;

  // const {
  //   // state: {
  //   //   user: { isLoggedIn }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const dispatchRedux = useDispatch()

  const {
    query: { tab: currentTab = getDefaultTab({ lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled }) }
  } = useRouter();

  const { trackEvent } = useSegment();

  const [productCounterType, setProductCounterType] = useState('count');
  const [showLoader, setShowLoader] = useState(false)

  const autoDismissWidgetTimeout = useRef(null);

  const {
    product_details_id: productDetailsId,
    master_product_id: masterProductId,
    product_name: productName = '',
    product_image: productImage = '',
    image_alt_tag: imageAltTag = '',
    tag_line: tagLine = '',
    tile_background_colour: tileBackgroundColour = '',
    brands = [],
    brand_names: brandNames = [],
    product_strain_type_name: strainName = '',
    product_weight: productWeight = '',
    product_unit: productUnit = '',
    product_attributes: productAttributes = [],
    is_deal_available: isDealAvailable,
    is_pre_deal_available: isPreDealAvailable,
    price_without_deal: priceWithoutDeal,
    bulk_base_price: bulkBasePrice,
    slug,
    is_sold_out: isSoldOut,
    full_shop_category_name: fullShopCategoryName,
    category_name: categoryName,
    newDiscountedPrice,
    product_price: productPrice,
    price,
    coupon,
    category_currency: categoryCurrency
  } = productDetails;

  const imgUrl = getTransformedImageURLs(productImage).srcSmall;
  const weight = productUnit === '1/8 oz' || productUnit === '1/2 oz' ? `${productUnit}` : `${productWeight} ${productUnit}`;
  const highestProductAttribute = getHighestValueAttribute(productAttributes, productWeight, productUnit);
  const originalPrice = isDealAvailable || isPreDealAvailable ? priceWithoutDeal : bulkBasePrice;
  const productUrl = `/product/${slug}?tab=${currentTab}`;

  // const isItemInCart = cartItems.find(cartItem => Number(cartItem.product_details_id) === Number(productDetailsId));

  function openProductPopup(event) {
    event.preventDefault();

    trackEvent('Product Clicked', {
      product_id: masterProductId,
      sku: masterProductId,
      category: fullShopCategoryName,
      category_frontend: categoryName,
      name: productName,
      brand: brandNames?.[0] || '',
      product_attributes: productAttributes?.join('/') || '',
      variant: weight,
      price: newDiscountedPrice || productPrice || price,
      original_price: priceWithoutDeal,
      coupon,
      strain_type: strainName,
      currency: categoryCurrency === '$' ? 'usd' : categoryCurrency || 'usd',
      position: productIndex,
      quantity: 1,
      url: `${window.location.origin}${productUrl}`,
      image_url: productImage,
      is_featured_product: true
    });

    const position = `featured-product-${productDetailsId}-${currentTab}`;

    // dispatch({ type: 'setPositionID', payload: { position } });

    dispatchRedux(setPositionID({ position }))
    dispatchRedux(popNotification())

    dispatchRedux(pushNotification({
      type: notificationTypes.productDetails,
      data: {
        slug,
        tab: currentTab,
        isFeaturedProduct: true,
        isFavourite: isLoggedIn ? productDetails?.is_favourite : storageService.isInFavourites(productDetails)
      }
    }))
    dispatchRedux(increaseURLstack())

    // dispatch({
    //   type: 'setUrlBeforeProduct',
    //   payload: { urlBeforeProduct: `${window.location.pathname + window.location.search}` }
    // });
    dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: `${window.location.pathname + window.location.search}` }))

    window.history.replaceState({ ...window.history.state, as: productUrl, url: productUrl }, '', productUrl);
  }

  const startAutoDismissWidget = useCallback(() => {
    clearTimeout(autoDismissWidgetTimeout.current);

    autoDismissWidgetTimeout.current = setTimeout(() => {
      setProductCounterType('count');
    }, 5000);
  }, [])

  const handleProductClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    if (productCounterType === 'count') {
      setProductCounterType('counter');

      startAutoDismissWidget();
    }

  }, [productCounterType])

  // function handleProductClick(event) {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   if (productCounterType === 'count') {
  //     setProductCounterType('counter');

  //     startAutoDismissWidget();
  //   }
  // }

  function handleBrandClick() {
    trackEvent('Brand Clicked', {
      product_id: masterProductId,
      sku: masterProductId,
      category: fullShopCategoryName,
      category_frontend: categoryName,
      name: productName,
      brand: brandNames?.[0] || '',
      product_attributes: productAttributes?.join('/') || '',
      variant: weight,
      price: newDiscountedPrice || productPrice || price,
      original_price: priceWithoutDeal,
      coupon,
      strain_type: strainName,
      currency: categoryCurrency === '$' ? 'usd' : categoryCurrency || 'usd',
      position: productIndex,
      quantity: 1,
      url: `${window.location.origin}/brands/${brands[0]}`,
      image_url: productImage,
      is_featured_product: true
    });
  }

  const dismissWidget = useCallback(() => {
    setProductCounterType('count');
  }, [])

  return (
    <div
      id={`featured-product-${productDetailsId}-${currentTab}`}
      style={{ '--featuredTileBackgroundColour': tileBackgroundColour }}
      className="featured-tile-main relative"
    >
      <a
        href={productUrl}
        onClick={openProductPopup}
        style={{ '--featuredTileImgBorderColour': tileBackgroundColour }}
        className="feat-img"
      >
        <LoadImage src={imgUrl} alt={imageAltTag || productName} avoidLazyLoad />
      </a>

      <a
        href={productUrl}
        onClick={openProductPopup}
        className=" d-block no-text-decoration feat-title text-right d-flex align-items-center justify-content-end fs-18"
      >
        {tagLine}
      </a>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {brands?.length && brandNames?.length ? (
            <Link
              prefetch={false}
              href={{ pathname: '/brands/[slug]', query: { tab: currentTab } }}
              as={{ pathname: `/brands/${brands[0]}`, query: { tab: currentTab } }}
            >
              <a
                className="feat-brand d-inline text-uppercase text-left ml-3 text-decoration-none"
                onClick={handleBrandClick}
                href={`/brands/${brands[0]}?tab=${currentTab}`}
              >
                {brandNames[0]}
              </a>
            </Link>
          ) : null}
        </div>
        {isWishlistApplicable ? <FavouritesIcon
          initialLocationAddress={initialLocationAddress}
          initialFreightLimit={initialFreightLimit}
          initialPurchaseLimit={initialPurchaseLimit}
          initialUpsellProducts={initialUpsellProducts}
          initialDeliveryAddressDetails={initialDeliveryAddressDetails}
          data={productDetails}
          isFeaturedProduct page={from} /> : null}
      </div>

      <a
        href={productUrl}
        onClick={openProductPopup}
        className="d-block no-text-decoration feat-product-name text-left ml-3 bold"
      >
        {productName}
      </a>

      <div className="feat-product-details text-left ml-3">
        {strainName ? (
          <>
            <span className="text-uppercase fs-16">{`${strainName} `}</span>
            <span className="vertical-yellow">| </span>
          </>
        ) : null}

        {highestProductAttribute?.[0] ? (
          <>
            <span className="text-uppercase fs-16">
              {`${highestProductAttribute[0].name} - ${getProductAttributeValue(highestProductAttribute[0])} `}
            </span>
          </>
        ) : null}
      </div>

      <div className="feat-price-container text-left d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Price productDetails={productDetails} orignalPrice={originalPrice} />

          {weight ? (
            <>
              <span className="vertical-yellow">| </span>
              <span className="ml-2">{`${weight}`}</span>
            </>
          ) : null}
        </div>
        <div data-testid="#add-to-cart" className="new-add-to-cart">
          <AddToCartButton
            isSoldOut={isSoldOut}
            handleProductClick={handleProductClick}
            // itemInCart={isItemInCart}
            productCounterType={productCounterType}
            productIndex={productIndex}
            data={productDetails}
            categoryIndex="0"
            setShowLoader={setShowLoader}
          />

          {process.browser ? (
            <ProductCounter
              page={currentTab === ASAP ? 'Featured ASAP List' : 'Featured Schedule List'}
              className="version-2"
              actionMade={startAutoDismissWidget}
              dismiss={dismissWidget}
              type={productCounterType}
              product={productDetails}
              // itemInCart={isItemInCart}
              // quantity={isItemInCart?.quantity || 0}
              onDeleteItem={dismissWidget}
              initialLocationAddress={initialLocationAddress}
              initialFreightLimit={initialFreightLimit}
              initialPurchaseLimit={initialPurchaseLimit}
              initialUpsellProducts={initialUpsellProducts}
              initialDeliveryAddressDetails={initialDeliveryAddressDetails}
              from={from}
              fromPage={fromPageType}
              isFeaturedProduct
              setShowLoader={setShowLoader}
              showLoader={showLoader}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProductTile;
