import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { ALLOW_PRODUCT_COMPARE } from '../constants/verificationConst';
import { ASAP, SCHEDULE } from '../constants/deliveryType';

import appConfig from '../appConfig';

import FeaturedProductsSection from './DTC/grassdoor/FeaturedProductsSection';
import FloatingCompareButton from './FloatingCompareButton';
import FloatingFilterButton from './FloatingFilterButton';
import SelectedFiltersList from './SelectedFiltersList';
import CategorySkeleton from './DTC/CategorySkeleton';
import DeliveryTabs from './DTC/DeliveryTabs';
import RemainingTime from './RemainingTime';
import FreightLimit from './FreightLimit';
import BreadCrumbs from './BreadCrumbs';
import BrowseMenu from './BrowseMenu';
import Filter from './Filter';
import DesktopFilter from './DesktopFilter';
import InlineLoader from './InlineLoader';
import FreightMessageBanner from './FreightMessageBanner';
import FixedBottomBar from './FixedBottomBar';
import { isNewHomePageApplicable } from '../constants/feature';
import BrandMenu from './DTC/grassdoor/BrandMenu';

const TrackOrder = dynamic(() => import('./TrackOrder'), { ssr: false });

const ShopMenu = props => {
  const {
    bannerData,
    initialLocationAddress,
    initialDeliveryAddressDetails,
    currentTab,
    asapProductsCount,
    scheduledProductsCount,
    filters,
    setFilters,
    setPage,
    setInitial,
    unifiedMenu,
    setShowAsapOnly,
    showAsapOnly,
    asapEnabled,
    scheduleEnabled,
    setTab,
    // orders,
    toggleFilterPopup,
    filterApplied,
    isSolid,
    shopFilters,
    finalCategories,
    asapOnlyCats,
    fetching,
    debouncedTrack,
    initialFreightLimit,
    initialPurchaseLimit,
    initialUpsellProducts,
    filterList,
    asapCategories,
    scheduleCategories,
    isFilterPopupVisible,
    hideFilters,
    hideFreightLimit,
    hideRemainingTime,
    applyFilters,
    hideWhatsThis,
    topBrands,
    compare,
    handleFloatingButtonClick,
    breadcrumbPath,
    fromPage,
    from,
    featuredProducts,
    showSpecialCategory = false,
    showBentoTopBrands,
    lastSlotCutOff,
    asapEndTime,
    asapStartTime
  } = props;

  const [listLoading, setListLoading] = useState(false);
  const [displayFreightBanner, setDisplayFreightBanner] = useState(true);
  const handleFiltersChange = args => {
    setListLoading(true);
    applyFilters(args);
  };
  useEffect(() => {
    if (!fetching) {
      return setListLoading(false);
    }
  }, [fetching]);

  useEffect(() => {
    let timeout;
    if (listLoading && !fetching) {
      timeout = setTimeout(() => setListLoading(false), 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [listLoading, fetching]);

  return (
    <>
      <div className=" one-rem-mb  drinkcann-inner-page-holder maven-mb-0 smarty-mb-0 phinest-mb-0">
        <div className={`filter-product-page ${hideFilters ? 'hide-content' : ''}`}>

          <div className="g-right-sec">

            {isNewHomePageApplicable ? null : !appConfig.isGrassdoor && unifiedMenu === 1 ? (
              <BrowseMenu
                className="category-page-tabs"
                setShowAsapOnly={setShowAsapOnly}
                showAsapOnly={showAsapOnly}
                asapEnabled={asapEnabled}
              />
            ) : (
              <DeliveryTabs
                currentTab={currentTab}
                asapProductsCount={asapProductsCount}
                scheduledProductsCount={scheduledProductsCount}
                asapEnabled={asapEnabled}
                scheduleEnabled={scheduleEnabled}
                setTab={setTab}
                className="category-page-tabs"
                hideWhatsThis={hideWhatsThis}
                forMiniTabs={false}
              />
            )}
            {(hideRemainingTime && false) || (!appConfig.isGrassdoor && unifiedMenu == 1) ? null : !isNewHomePageApplicable ? (
              <RemainingTime
                setTab={setTab}
                currentTab={currentTab}
                initialDeliveryAddressDetails={initialDeliveryAddressDetails}
              />
            ) : null}
            {hideFreightLimit || (!appConfig.isGrassdoor && unifiedMenu == 1) ? null : !isNewHomePageApplicable ? (
              <FreightLimit currentTab={currentTab} initialDeliveryAddressDetails={initialDeliveryAddressDetails} />
            ) : null}

            {(appConfig.isGrassdoor || process.env.NEXT_PUBLIC_DEFAULT_SITES) ? (
              <TrackOrder />
            ) : null}
            <>
              {!hideFilters ? (
                <DesktopFilter
                  filterList={filters}
                  currentTab={currentTab}
                  applyFilters={handleFiltersChange}
                  cancelFilters={() => toggleFilterPopup(true)}
                  deviceType="desktop"
                  isFilterApplied={filterApplied}
                  className="d-none d-lg-block"
                  shadowHeight={400}
                >
                  {' '}
                  <SelectedFiltersList
                    setListLoading={setListLoading}
                    filtersList={shopFilters}
                    filtersType="shopFilter"
                    className="sticky-filter-list"
                    applyFiltersCopy={handleFiltersChange}
                  />
                </DesktopFilter>
              ) : null}

              {!isNewHomePageApplicable ? (
                <SelectedFiltersList
                  setListLoading={setListLoading}
                  filtersList={shopFilters}
                  filtersType="shopFilter"
                  className="d-lg-none"
                  applyFiltersCopy={handleFiltersChange}
                />
              ) : null}

              {/* Do not remove */}
              <div id="list-top" />

              {listLoading && (
                <div className="text-center my-3">
                  <InlineLoader positionRelative className="big" />
                </div>
              )}
              {breadcrumbPath && !isNewHomePageApplicable ? <BreadCrumbs paths={breadcrumbPath} /> : null}

              <FeaturedProductsSection
                featuredProducts={featuredProducts}
                initialLocationAddress={initialLocationAddress}
                initialFreightLimit={initialFreightLimit}
                initialPurchaseLimit={initialPurchaseLimit}
                initialUpsellProducts={initialUpsellProducts}
                initialDeliveryAddressDetails={initialDeliveryAddressDetails}
                from={from}
                fromPageType={fromPage}
                lastSlotCutOff={lastSlotCutOff}
                asapEndTime={asapEndTime}
                asapStartTime={asapStartTime}
                asapEnabled={asapEnabled}
              />

              <div style={{ minHeight: '100vh' }}>
                <CategorySkeleton
                  setTab={setTab}
                  setTrack={debouncedTrack}
                  categories={currentTab === ASAP ? asapCategories : scheduleCategories}
                  currentTab={currentTab}
                  fetching={fetching}
                  asapEnabled={asapEnabled}
                  asapProductsCount={asapProductsCount}
                  scheduledProductsCount={scheduledProductsCount}
                  scheduleEnabled={scheduleEnabled}
                  unified_menu={unifiedMenu}
                  initialLocationAddress={initialLocationAddress}
                  initialFreightLimit={initialFreightLimit}
                  initialPurchaseLimit={initialPurchaseLimit}
                  initialUpsellProducts={initialUpsellProducts}
                  initialDeliveryAddressDetails={initialDeliveryAddressDetails}
                  topBrands={topBrands}
                  applyFilters={filters => {
                    setListLoading(true);
                    if (!filters) {
                      setFilters(filterList);
                    } else {
                      setInitial(false);
                      setFilters(filters);
                      setPage ? setPage(false) : null;
                    }
                  }}
                  secondaryBannerData={bannerData?.[currentTab]?.secondary || []}
                  tertiaryBannerData={bannerData?.[currentTab]?.tertiary || []}
                  fromPage={fromPage}
                  showSpecialCategory={showSpecialCategory}
                />

              </div>
              {showBentoTopBrands ? <BrandMenu topBrands={topBrands} showBentoTopBrands={showBentoTopBrands} /> : null}
            </>
          </div>
        </div>
      </div>
      {!hideFilters && isFilterPopupVisible ? (
        <Filter
          currentTab={currentTab}
          isFilterPopupVisible={isFilterPopupVisible}
          isFilterApplied={filterApplied}
          filterList={filters}
          applyFilters={tempFilters => {
            setListLoading(true);
            setFilters(tempFilters);
            setPage ? setPage(false) : null;
          }}
          cancelFilters={() => toggleFilterPopup(false)}
          asapProductsCount={asapProductsCount}
          scheduledProductsCount={scheduledProductsCount}
          deviceType="mobile"
          isPopup
        />
      ) : // </CSSTransition>
        null}
      <div className="d-none d-lg-block">
        {hideFreightLimit || (!appConfig.isGrassdoor && unifiedMenu == 1) ? null : displayFreightBanner ? (
          <FreightMessageBanner
            currentTab={currentTab}
            initialDeliveryAddressDetails={initialDeliveryAddressDetails}
            dismiss={() => setDisplayFreightBanner(false)}
          />
        ) : null}
      </div>
      {isNewHomePageApplicable ? (
        <FixedBottomBar
          scrollDirectionTop={false}
          tab={currentTab}
          setTab={setTab}
          hideFreightLimit={hideFreightLimit}
          unifiedMenu={unifiedMenu}
          displayFreightBanner={displayFreightBanner}
          asapEnabled={asapEnabled}
          scheduleEnabled={scheduleEnabled}
          asapProductsCount={asapProductsCount}
          scheduledProductsCount={scheduledProductsCount}
          hideFilters={hideFilters}
          filters={filters}
          toggleFilterPopup={toggleFilterPopup}
          filterApplied={filterApplied}
          setDisplayFreightBanner={setDisplayFreightBanner}
          initialDeliveryAddressDetails={initialDeliveryAddressDetails}
        />
      ) : null}
      {!hideFilters ? (
        isNewHomePageApplicable ? null : (
          <FloatingFilterButton
            filters={filters}
            toggleFilterPopup={toggleFilterPopup}
            filterApplied={filterApplied}
            isSolid={isSolid}
            className="d-lg-none"
          />
        )
      ) : null}
      {ALLOW_PRODUCT_COMPARE && compare?.productList?.length ? (
        <FloatingCompareButton
          isSolid={isSolid}
          products={compare?.productList || []}
          handleOnClick={() => {
            handleFloatingButtonClick();
          }}
        />
      ) : null}
    </>
  );
};

export default ShopMenu;
