export default [
  {
    desktop_image_url: 'https://prod-serverless.grassdoor.com/grassdoor.com/carousel/desktop/Homepage_banner_1685118302.jpg',
    desktop_image_thumb_url:
      'https://prod-serverless.grassdoor.com/grassdoor.com/carousel/desktop/thumb/Homepage_banner_1685118302.jpg',
    mobile_image_url: 'https://prod-serverless.grassdoor.com/grassdoor.com/carousel/mobile/Homepage_banner_1685118302.jpg',
    mobile_image_thumb_url:
      'https://prod-serverless.grassdoor.com/grassdoor.com/carousel/mobile/thumb/Homepage_banner_1685118302.jpg',
    header: null,
    sub_header: null,
    paragraph: '',
    button_text: '',
    button_link: '/referral',
    name: '',
    menu_type: 1,
    is_primary: 0,
    id: 2083,
    alt_text: 'Refer a Friend'
  },
  {
    desktop_image_url: 'https://prod-serverless.grassdoor.com/grassdoor.com/carousel/desktop/Homepage_banner_1675703555.jpg',
    desktop_image_thumb_url:
      'https://prod-serverless.grassdoor.com/grassdoor.com/carousel/desktop/thumb/Homepage_banner_1675703555.jpg',
    mobile_image_url: 'https://prod-serverless.grassdoor.com/grassdoor.com/carousel/mobile/Homepage_banner_1675703555.jpg',
    mobile_image_thumb_url:
      'https://prod-serverless.grassdoor.com/grassdoor.com/carousel/mobile/thumb/Homepage_banner_1675703555.jpg',
    header: null,
    sub_header: null,
    paragraph: '',
    button_text: '',
    button_link: '/friends-family',
    name: '',
    menu_type: 1,
    is_primary: 0,
    id: 2090,
    alt_text: 'Join Friends & Family'
  }
];
