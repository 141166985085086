import React from 'react';
import dynamic from 'next/dynamic';

const GrassdoorCategoriesSkeleton = dynamic(() => import('./grassdoor/CategoriesSkeleton'));

const CategorySkeleton = props => {
  return <GrassdoorCategoriesSkeleton {...props} />;
};

export default CategorySkeleton;
