import React from 'react';

const BrowseMenu = ({ className, setShowAsapOnly, showAsapOnly, asapEnabled }) => {

  function handleChange(e) {
    setShowAsapOnly(e.target.checked)
  }

  return (
    <div className={`delivery-tabs  d-flex justify-content-between   ${className}`}>
      <div className="m-item pl-3">

        <div className="content">

          <h3 className="bold m-title-browse d-inline unified-title">Browse Menu</h3>

        </div>

      </div>
      {asapEnabled ? <div className="custom-checkbox one-rem-mt pr-3 ">
        <input type="checkbox" id="showAsapOnly" name="showAsapOnly" onChange={handleChange} checked={showAsapOnly} />
        <label className="align-top m-0 text-left pl-4" htmlFor="showAsapOnly" />
        <span className="inner normal-font pl-1">Show ASAP Products</span>
      </div> : null}
    </div>
  );
};

export default BrowseMenu;
