import React from 'react';
import { useRouter } from 'next/router';

import { isFeaturedProductApplicable, isNewHomePageApplicable } from '../../../constants/feature';
import { ASAP, SCHEDULE } from '../../../constants/deliveryType';

import getDefaultTab from '../../../helpers/getDefaultTab';

import FeaturedProductTile from './FeaturedProductTile';
import CustomSlider from '../../CustomSlider';
import { useDeliveryDetails } from '../../../hooks/app';
import { productSliderDesktop } from './CategoriesSkeleton';
import { Carousel } from 'react-responsive-carousel';
import { rcSettings } from '../../ImageGridCarousel';

const FeaturedProductsSection = props => {
  const { featuredProducts, lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled, initialLocationAddress, initialFreightLimit,
   initialPurchaseLimit, initialUpsellProducts, initialDeliveryAddressDetails
  } = props;

  const {
    query: { tab = getDefaultTab({ lastSlotCutOff, asapEndTime, asapStartTime, asapEnabled }) }
  } = useRouter();

  if (isFeaturedProductApplicable && featuredProducts?.length) {
    return (
      <div className={`featured-tiles-wrapper ${featuredProducts.length === 1 ? "one-product-available" : ""}`}>
        <div className='d-lg-none'>
          <Carousel {...rcSettings} showArrows={false} swipeable showIndicators>
            {
              featuredProducts.filter(product => !product.is_sold_out).map((product, index) =>
                <FeaturedProductTile
                  key={`${tab}_${product.product_details_id}`}
                  productDetails={product}
                  productIndex={index}
                  {...props}
                  initialLocationAddress={initialLocationAddress}
                  initialFreightLimit={initialFreightLimit}
                  initialPurchaseLimit={initialPurchaseLimit}
                  initialUpsellProducts={initialUpsellProducts}
                  initialDeliveryAddressDetails={initialDeliveryAddressDetails}
                  lastSlotCutOff={lastSlotCutOff}
                  asapEndTime={asapEndTime}
                  asapStartTime={asapStartTime}
                  asapEnabled={asapEnabled}
                />)
            }
          </Carousel>
        </div>
        <div className='d-none d-lg-block'>
          <CustomSlider {...(isNewHomePageApplicable ? { ...productSliderDesktop } : {})}>
            {featuredProducts.map((product, index) => {
              const isSoldOut = product.is_sold_out;

              if (!isSoldOut) {
                return (
                  <FeaturedProductTile
                    key={`${tab}_${product.product_details_id}`}
                    productDetails={product}
                    productIndex={index}
                    {...props}
                    lastSlotCutOff={lastSlotCutOff}
                    asapEndTime={asapEndTime}
                    asapStartTime={asapStartTime}
                    asapEnabled={asapEnabled}
                  />
                );
              }

              return null;
            })}
          </CustomSlider>
        </div>
      </div >
    );
  }

  return null;
};

export default FeaturedProductsSection;
