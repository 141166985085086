import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Slider from 'react-slick';
import { getInImgFormate } from '../../../helpers/imageOptimization';
import CustomSlider from '../../CustomSlider';
import { isNewHomePageApplicable } from '../../../constants/feature';
import { TERTIARY } from '../../../constants/bannerTypes';

const rcSettings = {
  showArrows: true,
  autoPlay: false,
  showIndicators: false,
  showThumbs: false,
  infiniteLoop: true,
  interval: 8000,
  transitionTime: 800,
  showStatus: false,
  useKeyboardArrows: false,
  swipeable: true,
  emulateTouch: true,
  centerMode: true,
  centerSlidePercentage: 80
};

const GrassdoorSecondaryBanners = props => {
  const { secondaryBannerData, tertiaryBannerData, newBanner, firstBanner, secondBanner } = props;
  const router = useRouter();

  const onBannerClick = link => {
    if (link) {
      router.push(link);
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: secondaryBannerData?.length > 1 ? 2 : 1,
    slidesToScroll: secondaryBannerData?.length > 1 ? 2 : 1,
    mobileFirst: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };
  return !newBanner ? (
    !secondaryBannerData?.length ? null : isNewHomePageApplicable ? (
      <div
        className={`banner relative container banner-control-${secondaryBannerData?.length} default-site-banner ${isNewHomePageApplicable ? 'slider-wrap secondary-b' : 'marketing-banner'
          } ${!secondaryBannerData.length && 'no-secondary-banner'}`}
      >
        <Slider {...settings}>
          {secondaryBannerData?.map(item => (
            <div
              className={`carousel-item ${item.button_link ? 'cursor-pointer' : ''}`}
              key={item.id}
              onClick={() => onBannerClick(item.button_link)}
              role="presentation"
            >
              <picture>
                <source srcSet={getInImgFormate(item.desktop_image_url, 'webp').desktop} type="image/webp" />
                <source srcSet={getInImgFormate(item.desktop_image_url, 'jpeg').desktop} type="image/jpeg" />
                <img alt={item.alt_text} src={getInImgFormate(item.desktop_image_url).secondary} className="w-100 " />
              </picture>
            </div>
          ))}
        </Slider>
      </div>
    ) : (
      <CustomSlider {...rcSettings} className="gd-secondary-banner">
        {secondaryBannerData?.map(item => (
          <div
            className={`carousel-item ${item.button_link ? 'cursor-pointer' : ''}`}
            key={item.id}
            onClick={() => onBannerClick(item.button_link)}
            role="presentation"
          >
            <picture>
              <source srcSet={getInImgFormate(item.desktop_image_url, 'webp').desktop} type="image/webp" />
              <source srcSet={getInImgFormate(item.desktop_image_url, 'jpeg').desktop} type="image/jpeg" />
              <img alt={item.alt_text} src={getInImgFormate(item.desktop_image_url).secondary} className="w-100 " />
            </picture>
          </div>
        ))}
      </CustomSlider>
    )
  ) : (
    <>
      {tertiaryBannerData?.length ? (
        <div className="gd-secondary-banner friend-family-banner">
          <div className="row">
            {tertiaryBannerData?.map((tertiaryBanner, key) => {
              return (
                <div key={key} className={`col-lg-6 col-sm-6 mt-4 pt-2 pt-lg-0 mt-lg-0 fnf-wrap d-lg-flex`}>
                  <div className="fnf-box secondary-banner-box h-100 d-flex align-items-center">
                    {/* <Link href={tertiaryBanner.button_link}> */}
                    <div
                      className={`image-container ${tertiaryBanner.button_link ? 'cursor-pointer' : ''}`}
                      onClick={() => (tertiaryBanner.button_link ? redirectToLink(tertiaryBanner, key, TERTIARY) : null)}
                    >
                      <picture>
                        <source
                          srcSet={getInImgFormate(tertiaryBanner.desktop_image_url, 'webp').desktop}
                          type="image/webp"
                          media="(min-width: 992px)"
                        />
                        <source
                          srcSet={getInImgFormate(tertiaryBanner.desktop_image_url, 'png').desktop}
                          type="image/png"
                          media="(min-width: 992px)"
                        />
                        <source srcSet={getInImgFormate(tertiaryBanner.mobile_image_url, 'webp').mobile} type="image/webp" />
                        <source srcSet={getInImgFormate(tertiaryBanner.mobile_image_url, 'png').mobile} type="image/png" />
                        <img
                          alt={tertiaryBanner.alt_text}
                          src={getInImgFormate(tertiaryBanner.desktop_image_url, 'png').mobile}
                          className="img-fluid w-100"
                        />
                      </picture>
                    </div>
                    {/* </Link> */}
                    <div className="py-4 pb-4 content-wrap d-flex align-items-center justify-content-center">
                      {tertiaryBanner.header ? <h2>{tertiaryBanner.header}</h2> : null}
                      {tertiaryBanner?.paragraph ? (
                        <div class="perk-box d-flex px-2 justify-content-center">
                          <p>{tertiaryBanner.paragraph}</p>
                        </div>
                      ) : null}
                      {tertiaryBanner?.button_link ? (
                        <Link href={tertiaryBanner.button_link}>
                          <button className="btn btn-primary">{tertiaryBanner.button_text}</button>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );

  return null;
};

export default GrassdoorSecondaryBanners;
